import React from 'react'
import Footer from '../components/Footer/Footer'
import Navbar from "../components/Navbar/Navbar"
import UslugiPages from "../components/Uslugi/UslugiPages"
import {BoxTextPages, BigTitlePages,  Flex, FlexContent, TitleServices, ListServices} from '../components/Uslugi/UslugiStyled'
import { StaticImage }from "gatsby-plugin-image"


import { theme } from "../components/theme.js";
import { ThemeProvider } from "styled-components"


import SEO from "../components/seo"

const radiologia = () => {
  return (

    <>
    <ThemeProvider theme={theme}>
    <SEO 
    title="Radiologia - RTG"
    description="Oferujemy szeroki wachlarz usług weterynaryjnych obejmujący: wykonywanie zdjeć RTG, diagnozowanie i leczenie chorób psów, kotów, zwierząt futerkowych i gryzoni. 
     Jeżeli szukasz specjalisty lekarza weterynarii w Kaliszu - zapraszamy do Lecznicy dla Zwierząt znajdującej się w Kaliszu przy ul. Lipowej 17-21." 
     keywords="RTG zwierząt kalisz, RTG weterynarz kalisz"
    />
    <Navbar />
    <BigTitlePages style={{justifyContent: 'center'}}
         whileInView={{y: 0, opacity:1}}
         initial={{ y:50, opacity:0}}
         transition={{delay:0.25, duration: 0.75}}
         >Radiologia</BigTitlePages>
         
         <FlexContent>
         
          <UslugiPages />
 <Flex>

 <StaticImage
    src="../images/radiologia.jpeg"
    alt="Radiologia zwierząt"
    width={600}
    placeholder="blurred"
    className='staticimagepages'
    />

    <BoxTextPages>

<TitleServices> Wykonywanie przeglądowych zdjęć RTG</TitleServices>
<ListServices>Badanie układu oddechowego, przewodu pokarmowego, moczowego, tkanek miękkich
Zdjęcia w kierunku dysplazji u psów. < br />
Nasza pracownia RTG posiada wymagane przez Państwową Agencję Atomistyki certyfikaty i pozwolenia

</ListServices>
<ListServices> Dzięki nowoczesnej aparaturze firmy GIERTH wykonujemy zdjęcia w technice cyfrowej przy użyciu minimalnych dawek promieniowania
</ListServices>



    </BoxTextPages>

 
    
</Flex>
</FlexContent>
<Footer />
  </ThemeProvider>  
  </>
  )
}

export default radiologia